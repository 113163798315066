import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoolHeader, {SectionHeader} from '../components/Elements/CoolHeader'

const MarkDownContent = styled.div`
  ${'' /* margin: 1em auto; */}
  ${'' /* color: white; */}
  font-size: 0.7em;
  ${'' /* padding: 2em; */}
  ${'' /* background-color: ${transparentize(0.5, Theme.palette.primary.main)}; */}
  max-width: 750px;
`

const TOS = () => (
  <Layout>
    <SEO title="Terms And Conditions" />
    <CoolHeader>AGREEMENT</CoolHeader>
    <StaticQuery
      query={graphql`
        query TermsAndConditions {
          allMarkdownRemark(
            filter: {frontmatter: {title: {regex: "/tandc/"}}}
          ) {
            edges {
              node {
                id
                frontmatter {
                  title
                }
                html
              }
            }
          }
        }
      `}
      render={data => (
        <MarkDownContent
          className="blog-post-content"
          dangerouslySetInnerHTML={{
            __html: data.allMarkdownRemark.edges[0].node.html,
          }}
        />
      )}
    />
    <CoolHeader>TERMS AND CONDITIONS</CoolHeader>
    <p>
      BY ACCESSING THIS WEBSITE, YOU AGREE TO BE BOUND BY THE TERMS AND
      CONDITIONS (“TERMS”) BELOW. IF YOU DO NOT AGREE TO THE TERMS AND
      CONDITIONS BELOW, DO NOT USE THIS WEBSITE.
    </p>
    <CoolHeader>GENERAL USE RESTRICTIONS</CoolHeader>
    <p>
      Pomme Journal, or third parties granting rights to Pomme Journal, holds
      all right, title and interest in and to the information and content on
      this website (“Website”). These materials, including, but not limited to,
      (i) the company names, trade names, logos and designs (“Trademarks”) owned
      by Pomme Journal (including Pomme Journal); (ii) other trademarks
      appearing on the Website; and (iii) text, art and audio content, are
      protected by copyright, trademark and other intellectual property laws.
    </p>
    <p>
      Except as expressly authorized herein, you have no right to copy,
      download, display, perform, reproduce, distribute, modify, edit, alter or
      enhance any of the content on this Website in any manner. You are
      expressly prohibited from using any Trademarks, except as provided in
      these Terms, and nothing otherwise stated or implied on the Website
      confers on you any license or right to do so.
    </p>
    <p>
      Unauthorized use of this Website or misuse of any content posted on this
      Website is strictly prohibited. Unauthorized use of any Trademarks is
      prohibited under the trademark laws of the United States and other
      countries. The limited permission granted by these Terms and Conditions
      terminates automatically, without notice to you, if you breach any of
      these Terms.
    </p>
    <CoolHeader>UPDATES AND REVISIONS TO CONTENT & POLICIES</CoolHeader>
    <p>
      Pomme Journal publishes and promotes a wide range of voices and
      viewpoints. We intend to foster an open exchange of ideas, but we reserve
      the right in all instances to remove content from our website, other
      online/digital platforms, and/or printed content (or to not publish
      content) that we deem objectionable or offensive, or which we believe
      features items which do not meet Pomme Journal’s standards of ethics and
      social responsibility. In addition, we reserve the right to modify or
      delete content on our Website, other online/digital platforms and/or
      printed content due to changes in our editorial direction or vision or for
      other reasons.
    </p>
    <p>
      We may also modify these Terms (or our other policies) or the information
      on this Website without notice at any time, and such modifications shall
      be effective immediately upon posting of the modified Terms, policies,
      information or content on this Website. You agree to review these Terms
      periodically to be aware of such modifications and your continued access
      or use of this Website shall be deemed your conclusive acceptance of the
      modified Terms.
    </p>
    <CoolHeader>COMMENTS</CoolHeader>
    <p>
      Pomme Journal reserves the right to at any time, without notice, and in
      our sole discretion: (i) edit any comments you provide to the Website in
      response to a blog posting (“Comments”); (ii) not include any such Comment
      on the Website; (iii) remove some or all of any such Comment from the
      Website and from any other form of media; and (iv) terminate your ability
      to post Comments to the Website.
    </p>
    <p>
      Your Comments may not include any material that (a) defames, threatens,
      harasses, abuses or slanders any person; (b) invades or infringes any
      person’s rights of privacy or publicity; (c) violates copyrights or other
      intellectual property rights or that is otherwise unlawful; (d) is
      sexually explicit or obscene; (e) constitutes advertisements; or (f)
      solicits business.
    </p>
    <p>
      When you provide a Comment to the Website, you agree to accept sole
      responsibility for, and assume all liability (including for claims of
      infringement, libel and slander) associated with such Comment, including
      the information, statements, facts, and material contained therein.
    </p>
    <p>
      By providing a Comment to the Website, you grant us a world-wide, royalty
      free, perpetual, irrevocable, non-exclusive and freely sublicenseable
      right and license to use, reproduce, publish, translate, create derivative
      works from, distribute, and display your Comment anywhere, for any
      purpose, whether commercial or non-commercial, and in any form, media or
      technology now known or later developed.
    </p>
    <CoolHeader>
      THIRD-PARTY INTERACTION AND LINKS TO THIRD-PARTY WEBSITES
    </CoolHeader>
    <p>
      Opinions and other statements expressed by contributors are theirs alone
      and not opinions of Pomme Journal. Content created by any contributor is
      the sole responsibility of the contributor and Pomme Journal does not
      guarantee its accuracy and completeness.
    </p>
    <p>
      This Website may contain links to third-party websites that are not under
      our control or third parties may link to this Website. Links to or from a
      third-party website do not imply any affiliation between us and the
      website owner, or an endorsement, approval, or verification by us of any
      information or content available on such third-party websites. You agree
      that we are not responsible or liable for the accuracy or completeness of
      the content on any such third-party websites. These third-party websites
      may have a privacy policy different from ours and such third party
      websites may provide less security than this Website. By providing access
      to other websites, we are not recommending the purchase or sale of
      products or services provided by the sponsoring organization of any linked
      website.
    </p>
    <CoolHeader>SEVERABILITY</CoolHeader>
    <p>
      If any provision or portion of these Terms is held in whole or in part to
      be unenforceable for any reason, the remainder of that provision and the
      Terms will be severable and will remain in effect to the extent consistent
      with the intent of the Terms.
    </p>
    <CoolHeader>DISCLAIMER OF WARRANTIES</CoolHeader>
    <p>
      THIS WEBSITE AND ANY INFORMATION OR CONTENT CONTAINED IN THIS WEBSITE ARE
      PROVIDED TO YOU ON AN “AS IS” AND “WHERE-IS” BASIS, WITHOUT ANY WARRANTY.
      WE MAKE NO REPRESENTATIONS OR WARRANTIES IN CONNECTION WITH THIS WEBSITE
      AND WE EXPRESSLY DISCLAIM LIABILITY FOR ERRORS OR OMISSIONS IN ITS
      INFORMATION AND CONTENT. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES,
      WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING ANY IMPLIED
      WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
      NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, ARE HEREBY DISCLAIMED.
      CONTRIBUTORS, AND NOT SLICE LITERARY, INC., ARE SOLELY RESPONSIBLE FOR THE
      CONTENT THEY CONTRIBUTE TO THE WEBSITE.
    </p>
    <p>
      WE MAKE NO WARRANTY THAT (i) THE OPERATION OF THIS WEBSITE WILL MEET YOUR
      OR ANY OTHER USER’S REQUIREMENTS; (ii) ACCESS TO THIS WEBSITE WILL BE
      UNINTERRUPTED, TIMELY, SECURE, FREE OF VIRUSES OR OTHER HARMFUL
      COMPONENTS, OR FREE OF ERRORS; OR (iii) DEFECTS WILL BE CORRECTED. YOU
      ASSUME THE ENTIRE COST OF ALL SERVICING, REPAIR, OR CORRECTION THAT MAY BE
      NECESSARY FOR YOUR COMPUTER EQUIPMENT AND SOFTWARE AS A RESULT OF ANY
      VIRUSES, ERRORS, OR OTHER PROBLEMS YOU MAY HAVE AS A RESULT OF VISITING
      THIS WEBSITE.
    </p>
    <p>
      TO THE EXTENT THAT THE LAW DOES NOT PERMIT THE DISCLAIMER OF WARRANTIES,
      ALL CONTENT ACCESSIBLE ON THIS WEBSITE, OR ANY OTHER WEBSITE TO WHICH WE
      LINK, AND ALL OPERATIONS ON THIS WEBSITE ARE WARRANTED ONLY TO THE MINIMUM
      AMOUNT LEGALLY REQUIRED.
    </p>
    <CoolHeader>NO LIABILITY</CoolHeader>
    <p>
      IN USING THIS WEBSITE, YOU SPECIFICALLY AGREE THAT WE WILL NOT BE LIABLE
      TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT
      LIMITED TO, DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
      PUNITIVE DAMAGES, LOSSES OR EXPENSES ARISING FROM OR CONNECTED WITH THIS
      WEBSITE OR ANY LINKED WEBSITE OR USE THEREOF OR INABILITY TO USE BY ANY
      PARTY, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
      INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS
      OR LINE OR SYSTEM FAILURE, EVEN IF WE, OR REPRESENTATIVES THEREOF, ARE
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES.
    </p>
    <CoolHeader>INDEMNIFICATION</CoolHeader>
    <p>
      You agree to indemnify, hold harmless and, at our option, defend Pomme
      Journal and our officers, directors, employees, agents and representatives
      from any and all third-party claims, liability, damages and/or costs
      (including, but not limited to, reasonable attorneys’ fees and expenses)
      arising from your improper use of this Website or our products or
      offerings, your violation of these Terms, your infringement of any
      intellectual property or other right of any person or entity, or any
      Comment you provided to the Website.
    </p>
    <CoolHeader>DMCA NOTICE</CoolHeader>
    <p>
      The Digital Millennium Copyright Act (“DMCA”) provides a mechanism for
      notifying service providers of claims of unauthorized use of copyrighted
      materials. Under the DMCA, a claim must be sent to the service provider’s
      Designated Agent. If you believe in good faith that your copyrighted work
      has been reproduced on our Website without authorization in a way that
      constitutes copyright infringement, please notify this Website’s
      Designated Agent:
    </p>
    <p>Alicia at Pomme Journal: pommejournalcomm@gmail.com</p>
    <p>
      Please be aware that, in order to be effective, your notice of claim must
      comply with the detailed requirements set forth in the DMCA. You are
      encouraged to review them (see 17 U.S.C. Sec. 512(3)) before sending your
      claim.
    </p>
  </Layout>
)

export default TOS
